<template>
  <div>
    <custome-loader v-if="waitTimeoutPartners"></custome-loader>

    <v-row class="mt-10 px-5">
      <h2 class="mullerFont" style="font-size: 1.5rem">LES PARTENAIRES</h2>
    </v-row>
    <v-divider class="mt-3 mb-6"></v-divider>

    <v-row align="center" class="">
      <v-col cols="12" md="3">
        <v-text-field v-model="selectedPartner" label="Rechercher dans la liste"></v-text-field>
      </v-col>
      <v-col cols="12" md="1" align-self="center"> </v-col>
      <v-col cols="12" md="3" align-self="end" class="ml-5"> </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="2" v-for="(partner, index) in filteredPartnerItems" v-bind:key="partner.id">
        <v-card
          @mouseover="partner.hovered = true"
          @mouseleave="partner.hovered = false"
          class="rounded-xl align-center d-flex"
          outlined
          elevation="3"
          width="200"
          height="200"
          link
          :href="`/services/partners/items?id=${partner.partnerId}`"
        >
          <v-card-text class="text-center pa-5">
            <v-img
              class="mx-auto"
              max-width="80px"
              @error=";`error`"
              :src="`https://logo.clearbit.com/${partner.website_url}?size=128
          `"
              :alt="partner.website_url"
            ></v-img>
            <p class="mt-2">{{ partner.name }}</p>
          </v-card-text>
          <div class="hover-overlay rounded-xl">
            <span class="overlay-text">Consulter la fiche partenaire {{ partner.name }}</span>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import CustomeLoader from "@/layouts/components/LoaderCustom.vue"

// Import the CSS or use your own!
import "vue-toastification/dist/index.css"
import config from "@/views/config/config"
import { getCookie } from "@/utils/cookies"
export default {
  data: () => ({
    waitTimeoutPartners: false,
    partners: [],
    selectedPartner: "",
  }),
  async created() {
    this.fetchPartnersApproved()
    this.fetchPartnersDisapproved()
  },
  components: {
    CustomeLoader,
  },
  computed: {
    filteredPartnerItems() {
      // Filtrer les produits en fonction du texte saisi dans v-text-field
      const searchText = this.selectedPartner.toLowerCase()
      return this.partners.filter(partner => partner.name.toLowerCase().includes(searchText))
    },
  },

  methods: {
    async fetchPartnersApproved() {
      this.waitTimeoutPartners = true
      let agencyId = this.$store.state.user.agency_id

      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")
        headers.append("Authorization", `Bearer ${getCookie("jwt")}`)

        let response = await fetch(`${config.apiUri}/agencies/${agencyId}/partners/approved`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        }).finally(() => {
          this.waitTimeoutPartners = false
        })

        const data = await response.json()
        data.forEach(async element => {
          if (element.website_url !== "") {
            try {
              const response = await fetch(`https://logo.clearbit.com/${element.website_url}?size=128`)

              if (response.status === 200) {
                element.img = `https://logo.clearbit.com/${element.website_url}?size=128`
              } else {
                element.img = null
              }
            } catch (error) {
              element.img = null
            }
          } else {
            element.img = null
          }
          this.partners.push(element)
        })
      } catch (e) {
        console.log(e)
        this.$toast.error("Impossible d'accéder aux données lié aux comptes.\nMerci de réessayer ultérieurement.", {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },

    async fetchPartnersDisapproved() {
      let agencyId = this.$store.state.user.agency_id

      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")
        headers.append("Authorization", `Bearer ${getCookie("jwt")}`)

        let response = await fetch(`${config.apiUri}/agencies/${agencyId}/partners/disapproved`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })

        const data = await response.json()

        data.forEach(async element => {
          if (element.website_url !== "") {
            try {
              const response = await fetch(`https://logo.clearbit.com/${element.website_url}?size=128`)

              if (response.status === 200) {
                element.img = `https://logo.clearbit.com/${element.website_url}?size=128`
              } else {
                element.img = null
              }
            } catch (error) {
              element.img = null
            }
          } else {
            element.img = null
          }
          this.partners.push(element)
        })
      } catch (e) {
        console.log(e)

        this.$toast.error("Impossible d'accéder aux données lié aux comptes.\nMerci de réessayer ultérieurement.", {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },

    async fetchAgencyProducts() {
      this.productsAgency = []

      let agencyId = this.$store.state.user.agency_id
      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        const response = await fetch(`${config.apiUri}/agencies/${agencyId}/products`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })
        const data = await response.json()

        data.products.forEach(element => {
          element.particular === 1 ? this.productsParticularCount++ : this.productsParticularCount
          element.professionnal === 1 ? this.productsProfessionnalCount++ : this.productsProfessionnalCount
          this.productsAgency.push({
            id: element.id,
            name: element.name,
            sponsoring: element.sponsoring,
            professionnal: element.professionnal,
            particular: element.particular,
            insurers: element.insurers,
            hovered: false,
            icon: element.icon,
          })
        })
      } catch (e) {
        console.error(e)

        this.$toast.error("Impossible d'accéder aux données.\nMerci de réessayer ultérieurement.", {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.hover-overlay {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.2s ease-in-out;
}
.overlay-text {
  color: white;
  font-size: 12px !important;
  text-align: center;
  padding: 5px;
}
.v-card:hover .hover-overlay {
  opacity: 1;
}
</style>
